body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.g-card {
    margin: 20px
}

.g-card-image {
    border-radius: 10px;
    width: 350px;
    height: 480px;
    box-shadow: 0px 0px 3px 1px #ccc;
    position: relative;
}

.g-card-info {
    margin-top: 10px;
    min-height: 0px;
}

.g-card-title {
    font-size: 16px;
    margin: 0px;
}

.g-card-sub-title {
    font-size: 16px;
    margin: 0px;
}

.success-msg {
    color: #57A773;
    padding: 10px 15px;
}

.err-msg {
    color: #EE6352;
    padding: 10px 15px;
}

.about-img {
    width: 500px;
    height: 800px;
    margin-right: 500px;

}

.skill{
    max-width: 900px;
    margin-right: 20%;
    padding-left: 5px;
    padding-top: 90px;
}

.buttonDownload {
    display: inline-block;
    position: relative;
    padding: 2px 15px;


    background-color: #0747ef;
    color: white;

    font-family: sans-serif;
    text-decoration: none;
    font-size: 1.4em;
    text-align: center;
    text-indent: 10px;
}







.front,.back{
    border: 1px solid white;
    width: 350px;
    height: 500px;
    /*overflow: hidden;*/
    backface-visibility: hidden;
    position: absolute;
    left: -8vw;
    right: 0;
    margin-left: auto;
    margin-right: auto;

    /*margin-right: 1vw;*/
    transition: transform .6s linear;
    border-radius: 10px;
    /*margin-left: -180px*/


}
.front {
    background: whitesmoke;
    transform: perspective(600px) rotateY(0deg);
}
.back{
    background: skyblue;
    transform: perspective(600px) rotateY(-180deg);
    padding: 10px 15px;
}

.card.rotated > .front{
    transform: perspective(600px) rotateY(180deg);

    /*margin: 20px*/
}
.card.rotated > .back{
    transform: perspective(600px) rotateY(0deg);
    /*margin: 20px*/
    z-index: 10;
}














// Cards


* {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: 'avenir next', avenir, -apple-system, BlinkMacSystemFont,
    'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial,
    sans-serif;
    color: #374047;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

//body {
//    display: flex;
//    flex-direction: column;
//    height: 100%;
//}

.main {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    //background-color: #1e282f;
}

.info {
    position: relative;
    //z-index: 1;
    color: white;
    text-align: center;
    margin-bottom: 3rem;
    font-size: 14px;
    a {
        color: inherit;
    }

    .notice {
        //margin-top: 1rem;
        font-weight: 100;
    }

    @media screen and (min-width: 980px) {
        font-size: 18px;
        .notice {
            display: none;
        }
    }
}

.hero {
    //display: flex;
    //flex-direction: column;
    //align-items: stretch;
    //justify-content: space-between;

    //background: linear-gradient(180deg, #2a3267, #1e282f 100%);
}

.hero-body {
    //display: flex;
    //flex-grow: 1;
    //flex-shrink: 0;
    //padding: 3rem 1.5rem;

    //@media screen and (min-width: 980px) {
    //    padding: 8rem 1.5rem;
    //}
}

.container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
}

.row {
    //display: flex;
    //flex-wrap: wrap;
}

.column {
    display: flex;
    flex: 1 1 auto;
    padding: 10px;
    width: 100%;
    @media screen and (min-width: 980px) {
        width: 25%;
    }
}

.card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 10px 30px -5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.5s;
    will-change: transform;

}

.card-title {
    font-size: 22px;
    font-weight: 600;
}

.card-body {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 1.5;
    font-size: 16px;
    @media screen and (min-width: 576px) {
        font-size: 18px;
    }
}

.image-container {
    margin-top: auto;
    padding-top: 0px;
    padding-bottom: 0px;
    @media screen and (min-width: 576px) {
        padding-bottom: 30px;
    }

    img {
        width: 100%;
    }
}

.image-inner-container {
    width: 100%;
    margin: 0 auto;
    max-width: 400px;
}

.ratio {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.ratio-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}






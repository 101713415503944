.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.social-container {
  background: transparent;
  text-align: center;
  padding: 100px;
}

a.social {
  margin: 0 2rem;
  transition: transform 200ms;
  display: inline-block;
}

a.social:hover {
  transform: translateY(-10px);
}

a.github {
  color: black;
  position: relative;
}

a.linkedin{
  color: #0072b1;
  position: relative;
}



.type-1 {
  border-radius:10px;
  border: 3px solid #eee;
  transition: .9s border-color;
  display:block;
  width:500%;
  margin:10px 0;
  padding:10px;
}
.type-1:hover {
  border: 1px solid #aaa;
}





